import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createVNode as _createVNode, withCtx as _withCtx, isRef as _isRef, vModelText as _vModelText, withDirectives as _withDirectives, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { "data-e2e": "user-report-title" }
const _hoisted_2 = { class: "content" }
const _hoisted_3 = {
  class: "description",
  "data-e2e": "user-report-description"
}
const _hoisted_4 = {
  class: "description__label",
  "data-e2e": "user-report-description-label",
  for: "user-report-description-input"
}
const _hoisted_5 = ["maxlength", "placeholder"]
const _hoisted_6 = {
  class: "user-notice",
  "data-e2e": "user-report-notice"
}
const _hoisted_7 = {
  class: "footer",
  "data-e2e": "user-report-footer"
}

import { watch } from 'vue';
import { useRouter } from 'vue-router';
import { $t } from '@/i18n';
import type { Cancellable } from '@/utils/types';
import {
  notificationPlugin,
  NotificationType,
} from '@/features/core/notifications';
import { useOnlineStatus } from '@/features/orders';
import { BaseButton, ClearIcon, AppHeader } from '@/features/ui';
import { DESCRIPTION_MAX_LENGTH } from '../types';
import { useUserReport } from '../composables';


export default /*@__PURE__*/_defineComponent({
  __name: 'UserReport',
  setup(__props) {

const router = useRouter();

const { description, handleSubmit, hasError, processing } = useUserReport();

const { isOnline } = useOnlineStatus();
let offlineNotification: Cancellable;

watch(isOnline, () => {
  if (!isOnline.value) {
    offlineNotification = notificationPlugin.get().show({
      text: $t('pages.user-report.offline-notification.text'),
      type: NotificationType.Error,
      duration: -1,
    });
  } else {
    offlineNotification?.cancel();
  }
});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_unref(AppHeader), {
      borderBottom: "",
      "data-e2e": "user-report-header"
    }, {
      default: _withCtx(() => [
        _createElementVNode("h3", _hoisted_1, _toDisplayString(_unref($t)('pages.user-report.title.text')), 1),
        _createVNode(_unref(ClearIcon), {
          class: "close-icon",
          onClick: _cache[0] || (_cache[0] = ($event: any) => (_unref(router).back())),
          "data-e2e": "user-report-close-button"
        })
      ]),
      _: 1
    }),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("label", _hoisted_4, _toDisplayString(_unref($t)('pages.user-report.problem-description.label')), 1),
        _withDirectives(_createElementVNode("textarea", {
          "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => (_isRef(description) ? (description).value = $event : null)),
          maxlength: _unref(DESCRIPTION_MAX_LENGTH),
          placeholder: _unref($t)('pages.user-report.problem-description.placeholder'),
          class: "description__input",
          id: "user-report-description-input",
          "data-e2e": "user-report-description-input"
        }, null, 8, _hoisted_5), [
          [_vModelText, _unref(description)]
        ])
      ]),
      _createElementVNode("p", _hoisted_6, _toDisplayString(_unref($t)('pages.user-report.user-notice.text')), 1)
    ]),
    _createElementVNode("div", _hoisted_7, [
      _createVNode(_unref(BaseButton), {
        variant: "secondary",
        "data-e2e": "user-report-submit-button",
        label: _unref($t)('pages.user-report.submit-button.label'),
        disabled: _unref(hasError) || !_unref(isOnline) || _unref(processing),
        loading: _unref(processing),
        onClick: _unref(handleSubmit)
      }, null, 8, ["label", "disabled", "loading", "onClick"])
    ])
  ], 64))
}
}

})